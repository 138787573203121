@prefix: home;

.@{prefix} {
  // padding: 20px;
}
.@{prefix}-header {
  height: 62px;
  padding: 20px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.@{prefix}-content {
  margin-top: 30px;
  padding: 0 20px;
  .date{
    margin-bottom: 10px;
  }
 .view {
   display: flex;
   justify-content: center;
   background-color: white;
   align-items: center;
   padding: 20px;
   .ant-card-body {
     width: 300px;
   }
   .ant-descriptions-item-container {
     justify-content: center;
     align-items: center;
   }
   .ant-descriptions-item-content {
     flex: none;
   }
 }
}

@primary-color: #3388FF;@link-color: #3388FF;@success-color: #40C87F;@warning-color: #FFCD2E;@error-color: #F5535B;@font-size-base: 14px;@heading-color: #333333;@text-color: #333333;@text-color-secondary: #999999;@disabled-color: #cccccc;@border-radius-base: 2px;@border-color-base: #cccccc;