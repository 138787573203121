@import '../../styles/variable.less';

@prefix: core;

.@{prefix} {
    height: 100%;
}

.@{prefix}-header {
    height: 62px;
    padding: 20px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .wrap {
        .title {
            margin-right: 30px;
            float: left;
        }

        .auth-wrap {
            float: left;

            .item {
                position: relative;
                margin-right: 15px;
                color: #333;
            }

            .active {
                color: #1890ff;
            }

            .active::after {
                content: '';
                position: absolute;
                background-color: #1890ff;
                width: 100%;
                height: 4px;
                left: 0;
                bottom: -26px;
            }
        }
    }
}

.@{prefix}-content {
    margin: 20px;
    height: calc(100% - 102px);
    background-color: white;
    padding-top: 1px;
    .result {
        padding: 10px 20px 0;
    }

    .search {
        width: 100%;
        display: flex;
        padding-bottom: 20px;
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;

        &-box {
            width: 300px;
        }

        &-wrapper {
            display: flex !important;
            justify-content: space-between;
            align-items: flex-end;

            .search-device-name {
                width: 200px;
            }
        }
    }

    .pointer {
        cursor: pointer;
    }
}

.ico {
    color: #333;

    &:hover {
        color: @defaultBlue;
        cursor: pointer;
    }
}

.log-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
}

@primary-color: #3388FF;@link-color: #3388FF;@success-color: #40C87F;@warning-color: #FFCD2E;@error-color: #F5535B;@font-size-base: 14px;@heading-color: #333333;@text-color: #333333;@text-color-secondary: #999999;@disabled-color: #cccccc;@border-radius-base: 2px;@border-color-base: #cccccc;