@prefix: config;
@import '../../styles/variable.less';

.@{prefix} {
  height: 100%;
  
}
.@{prefix}-header {
  height: 62px;
  padding: 20px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.@{prefix}-content {
  margin: 20px;
  height: calc( 100% - 102px);
  background-color: white;

  .block {
    padding: 20px 20px 0;
  }
  .result {
    padding: 10px 20px 0;
  }
  .search {
    width: 100%;
	display: flex;
	padding-bottom: 20px;
	border-bottom: 1px solid #eee;
	margin-bottom: 20px;
    &-box {
        width: 300px;
    }

    &-wrapper {
        display: flex !important;
		justify-content: space-between;
        .search-device-name {
          width: 200px;
        }
    }

}

.pointer {
  cursor: pointer;
}
.operation {
  display: flex;
  justify-content: space-between;
}

}
.ico {
  color: #333;
  &:hover {
    color: @defaultBlue;
    cursor: pointer;
  }
}
@primary-color: #3388FF;@link-color: #3388FF;@success-color: #40C87F;@warning-color: #FFCD2E;@error-color: #F5535B;@font-size-base: 14px;@heading-color: #333333;@text-color: #333333;@text-color-secondary: #999999;@disabled-color: #cccccc;@border-radius-base: 2px;@border-color-base: #cccccc;