@home-prefix: login;
@import '../../styles/variable.less';

.@{home-prefix}-content {
  height: 100vh;
  background: #fff;
  .@{home-prefix}-header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 60px;
	line-height: 60px;
	padding: 0 30px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	font-family: PingFangSC-Regular;
	font-size: 14px;
	color: #ffffff;
	letter-spacing: 0;
  }
  .@{home-prefix}-box {
	width: 860px;
	height: 620px;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	margin: auto;
	display: flex;
	flex-flow: row nowrap;
	border-radius: 10px;
	box-shadow: 3px 3px 10px  rgba(0, 0, 0, 0.3);
	.@{home-prefix}-logo-box {
	  background-color: @baseBlue;
	  flex-grow: 1;
	  height: 100%;
	  display: flex;
	  flex-flow: column;
	  align-items: center;
	}
	.@{home-prefix}-title{
	  margin-top: 100px;
	  color: white;
	  font-size: 32px;
	}
	.@{home-prefix}-logo{
	  margin-top: 100px;
	  width: 320px;
	  height: 320px;
	  background: url('../../images/login/login_bg.png') center center no-repeat;
	  background-size: cover;
	}
	.@{home-prefix}-login-form {
	  background: white;
	  padding: 30px 80px;
	//   .ant-tabs-tabpane {
	// 	width: 86%;
	// 	margin: auto;
	// }
	  .ant-tabs-nav {
		.ant-tabs-nav-list {
		  .ant-tabs-tab {
			width: 120px;
			margin-right: 0;
			justify-content: center;
			&:hover {
			  color: @titleBlue;
			}
			&.ant-tabs-tab-active .ant-tabs-tab-btn {
			  color: @titleBlue;
			}
		  }
		}
	  }

	
	  
	}

	.ant-input-affix-wrapper {
	  height: 40px;
	}
	.ant-input-group-addon {
	  background-color: @baseBlue;
	  color: white;
	  cursor: pointer;
	}

	.get-code {

	  background-color: @baseBlue;
	}
	.countdown .ant-statistic-content {
	  width: 70px;
	  color: white;
	  font-size: 16px;
	}

	.items {
	  margin-top: 50px;
	}

	.@{home-prefix}-account {
	  height: 40px;
	}
	.@{home-prefix}-password {
	  margin: 10px 0;
	  height: 40px;
	}
	.@{home-prefix}-login-btn {
	  width: 341px;
	  height: 40px;
	  margin-top: 20px;
	  font-size: 16px;
	  border-radius: 5px;
	  background-color: @baseBlue;
	  border: 1px solid @baseBlue;
	  &:hover {
		opacity: .8;
	  }
	}
	.@{home-prefix}-others {
	  display: flex;
	  justify-content: space-between;
	  margin-bottom: 20px;
	}
	.@{home-prefix}-reset {
	  padding: 30px 50px 30px;
	  .steps {
		margin: 50px 0;
	  }
	  .items {
		margin: 0 auto;
		width: 341px;
	  }
	  .success {
		padding: 50px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	  }
	}
  }

}
.blue {
  color: @fontBlue;
}
.pointer {
  cursor: pointer;
}

.swing-enter {
  -webkit-animation-duration: .2s;
	animation-duration: .2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused
}
.swing-enter-active {
  -webkit-animation-name: swing-in;
	animation-name: swing-in;
	-webkit-animation-play-state: running;
	animation-play-state: running
}
@-webkit-keyframes swing-in{
  0%,to {
	-webkit-transform: translateX(0);
	transform: translateX(0)
}

20% {
	-webkit-transform: translateX(-10px);
	transform: translateX(-10px)
}

40% {
	-webkit-transform: translateX(10px);
	transform: translateX(10px)
}

60% {
	-webkit-transform: translateX(-5px);
	transform: translateX(-5px)
}

80% {
	-webkit-transform: translateX(5px);
	transform: translateX(5px)
}
}
@keyframes swing-in {
  0%,to {
	-webkit-transform: translateX(0);
	transform: translateX(0)
}

20% {
	-webkit-transform: translateX(-10px);
	transform: translateX(-10px)
}

40% {
	-webkit-transform: translateX(10px);
	transform: translateX(10px)
}

60% {
	-webkit-transform: translateX(-5px);
	transform: translateX(-5px)
}

80% {
	-webkit-transform: translateX(5px);
	transform: translateX(5px)
}
}

@primary-color: #3388FF;@link-color: #3388FF;@success-color: #40C87F;@warning-color: #FFCD2E;@error-color: #F5535B;@font-size-base: 14px;@heading-color: #333333;@text-color: #333333;@text-color-secondary: #999999;@disabled-color: #cccccc;@border-radius-base: 2px;@border-color-base: #cccccc;