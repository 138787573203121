@import "~antd/dist/antd.less";

.oms {
  &-header {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid #eff0f4;
    padding: 0 30px;
    &.ant-layout-header {
      height: 51px;
      line-height: 51px;
      background-color: white;

    }
    &-logo {
      padding: 10px 15px;
      margin: 16px 0;
      font-family: PingFangSC-Medium;
      font-size: 20px;
      color: white;
      letter-spacing: 0;
      text-align: center;
      display: flex;
      justify-content: space-between;
      .ico {
        display: inline-block;
        width: 29px;
        height: 24px;
        background: url(./images/home/logo.png);
        background-size: cover;
      }
      h5 {
        color: white;
      }
    }
  }
  &-content {
    // margin: 30px;
    // background: #fff;
  }

}

@primary-color: #3388FF;@link-color: #3388FF;@success-color: #40C87F;@warning-color: #FFCD2E;@error-color: #F5535B;@font-size-base: 14px;@heading-color: #333333;@text-color: #333333;@text-color-secondary: #999999;@disabled-color: #cccccc;@border-radius-base: 2px;@border-color-base: #cccccc;