@prefix: monitor;
@import '../../styles/variable.less';

.@{prefix} {
    height: 100%;

}

.@{prefix}-header {
    height: 62px;
    padding: 20px;
    background-color: white;
    display: flex;
    align-items: baseline;
}

.@{prefix}-content {
    margin: 20px;
    height: calc(100% - 102px);
    background: white;

    .block {
        padding: 20px 20px 0;
    }

    .hidden {
        display: none;
    }

    .result {
        padding: 10px 20px 0;
    }

    .ant-table-cell {
        padding: 10px !important;
    }

    .search {
        width: 100%;
        display: flex;
        padding-bottom: 20px;
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;

        &-box {
            width: 300px;
        }

        &-wrapper {
            display: flex !important;
            justify-content: space-between;

            .left {
                padding: 0 20px;
                display: flex;
                flex-wrap: wrap;

                .search-ida {
                    margin-right: 12px;
                }
            }

            .search-device-name {
                width: 200px;
            }
        }

    }
    .gutter-row {
        height: 200px;
    }
    .ant-card {
        background-color: rgba(240, 242, 245, .5);
    }
    .operation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .left {
            flex: 1;
			> button {
				margin-right: 10px;
			}
        }

        .right {
            width: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .anticon-setting {
                margin: 0 10px;
            }
        }
    }

}

.ico {
    color: #333;

    &.disabled {
        color: #ccc;

        &:hover {
            color: #ccc;
            cursor: not-allowed;
        }
    }

    &:hover {
        color: @defaultBlue;
        cursor: pointer;
    }
}

.monitor-wrap {
    width: 100%;
    height: 100%;

    .monitor {
        width: 100%;
        height: 100%;
    }
}

.alarm-item {
    border-top: 1px solid #f0f0f0;
    padding: 5px 0;
    text-align: center;
  }
@primary-color: #3388FF;@link-color: #3388FF;@success-color: #40C87F;@warning-color: #FFCD2E;@error-color: #F5535B;@font-size-base: 14px;@heading-color: #333333;@text-color: #333333;@text-color-secondary: #999999;@disabled-color: #cccccc;@border-radius-base: 2px;@border-color-base: #cccccc;