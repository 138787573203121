.triangle {
  display: inline-block;
	width: 0; 
   height: 0;
   margin-left: 5px;
 	border-width: 4px;
 	border-style: solid;
 	border-color:#333 transparent transparent transparent;
 }

@primary-color: #3388FF;@link-color: #3388FF;@success-color: #40C87F;@warning-color: #FFCD2E;@error-color: #F5535B;@font-size-base: 14px;@heading-color: #333333;@text-color: #333333;@text-color-secondary: #999999;@disabled-color: #cccccc;@border-radius-base: 2px;@border-color-base: #cccccc;